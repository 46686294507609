// Edit AOI group properties

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import useStore from "../store";
import CloseIcon from '@mui/icons-material/Close';
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_orange, DEFAULT_AOI_COLOR, theme_limeGreen, theme_errorRed } from "../Theme";
import { AOI_INDEX_MAP_AOI_ID } from "./Aois";
import { MuiColorInputStyled } from "../LayerLibrary/EditLayer/EditLayerStyle";
import { MuiColorInputColors } from "mui-color-input";
import { CustomTextField } from "../LayerLibrary/EditLayer/EditLayer";
import { ChangeEvent, useEffect, useState } from "react";
import { IAoiAttribute, IAoiAttributeValue, IAoiGroupProperties } from "./AoiInterfaces";
import { SaveAoiGroupProperties, UpdateAoiIndexMap } from "./AoiOps";
import { ToastNotification } from "../ToastNotifications";
import { EditAoiAttributeList } from "./EditAoiAttributeList";

export const AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_INDEX_MAP: number = 1;
export const AOI_ADMIN_ATTRIBUTE_ID_COLOR: number = 2;

export const AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_INDEX_MAP: boolean = true;

export const AOI_GROUP_PROP_DEFAULT_INDEX_MAP_BORDER_THICKNESS: number = 3;
export const AOI_GROUP_PROP_DEFAULT_INDEX_MAP_BORDER_HALO: boolean = true;
export const AOI_GROUP_PROP_DEFAULT_INDEX_MAP_FILL_OPACITY: number = 0.10;

const INDEX_MAP_MIN_BORDER_THICKNESS: number = 0;
const INDEX_MAP_MAX_BORDER_THICKNESS: number = 10;

const AOI_ATTRIBUTE_VALUE_TEXT_MAX_LENGTH: number = 100;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditAoiGroupPropertiesProps
{
}

//-------------------------------------------------------------------------------
// Edit AOI group properties
//-------------------------------------------------------------------------------
export function EditAoiGroupProperties(props: EditAoiGroupPropertiesProps) 
{
  // Get needed state data from the store
  const { store_editAoiGroupProperties, store_setEditAoiGroupProperties, store_aoi, 
          store_project, store_aoiGroupProperties, store_setEditAoiAttributes,
          store_aoiGroupPropertiesIsSaving
        } = useStore();


  const [localAoiGroupProps, setLocalAoiGroupProps] = useState<IAoiGroupProperties|undefined>(undefined);
  const [changesWereMade, setChangesWereMade] = useState<boolean>(false);









  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Initializes the local UI with values from the state store
    if(store_editAoiGroupProperties === true)
    {
      setLocalAoiGroupProps(store_aoiGroupProperties);
      setChangesWereMade(false);
    }

  }, [store_editAoiGroupProperties]);

  //-------------------------------------------------------------------------------
  // Cancel without saving changes.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    store_setEditAoiGroupProperties(false);
    setLocalAoiGroupProps(undefined);
    setChangesWereMade(false);
  }

  //-------------------------------------------------------------------------------
  // Save changes and close.
  //-------------------------------------------------------------------------------
  const OnAcceptChanges = async () => 
  {
    // Validation

    if(!localAoiGroupProps) 
      return;

    if(localAoiGroupProps.index_map_border_thickness < INDEX_MAP_MIN_BORDER_THICKNESS || localAoiGroupProps.index_map_border_thickness > INDEX_MAP_MAX_BORDER_THICKNESS)
    {
      ToastNotification('error', `Invalid border thickness (valid range is ${INDEX_MAP_MIN_BORDER_THICKNESS} - ${INDEX_MAP_MAX_BORDER_THICKNESS})`);
      return;
    }

    if(localAoiGroupProps.index_map_fill_opacity < 0 || localAoiGroupProps.index_map_fill_opacity > 1)
    {
      ToastNotification('error', `Invalid fill opacity (valid range is 0 - 1)`);
      return;
    }
  
    for(let i=0; i < localAoiGroupProps.attributes.length; i++)
    {
      // Nothing within each AOI needs validation currently...
    }

    // If changes were made, save the changes to the database

    if(changesWereMade === true)
    {
      // First update the state store and the map
      useStore.getState().store_setAoiGroupProperties(localAoiGroupProps);

      // If we are in "Aoi Index Map" mode, need to visually refresh the map
      if(useStore.getState().store_aoi?.aoi_id === AOI_INDEX_MAP_AOI_ID)
        UpdateAoiIndexMap();
    
      // Save changes to the database
      const ret: boolean = await SaveAoiGroupProperties(localAoiGroupProps);

      if(ret === true)  // If the save worked, close the window
        OnClose();
    }
    else // No changes were made - close the window
      OnClose();
  }

  //-------------------------------------------------------------------------------
  // An AOI checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnAoiCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, aoi_id: number) => 
  {
    if(!localAoiGroupProps) return;

    const attrib: IAoiAttribute | undefined = localAoiGroupProps.attributes.find(a => a.id === AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_INDEX_MAP);
    if(!attrib) return;

    let newAoiAttribute: IAoiAttribute;

    let attribValueForAoi: IAoiAttributeValue | undefined = attrib.values.find(v => v.aoi_id === aoi_id);
    if(attribValueForAoi)
    {
      // An AOI value item already exists - we update it

      const newAoiAttribValue: IAoiAttributeValue =
      {
        ...attribValueForAoi,
        value: checked ? "true" : "false"
      }
  
      newAoiAttribute =
      {
        ...attrib,
        values: attrib.values.map(oldValue => oldValue.aoi_id === aoi_id ? newAoiAttribValue : oldValue)
      }
    }
    else
    {
      // Create a new AOI value item and add it to the list

      const newAoiAttribValue: IAoiAttributeValue =
      {
        aoi_id: aoi_id,
        value: checked ? "true" : "false"
      }

      newAoiAttribute =
      {
        ...attrib,
        values: [...attrib.values,newAoiAttribValue]
      }
    }

    // Replace the old attrib with the updated version

    const newLocalAoiGroupProps: IAoiGroupProperties = 
    {
      ...localAoiGroupProps,
      attributes: localAoiGroupProps.attributes.map(oldAttrib => oldAttrib.id === AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_INDEX_MAP ? newAoiAttribute : oldAttrib)
    }

    // Set the new value
    setLocalAoiGroupProps(newLocalAoiGroupProps);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // An AOI color has changed.
  //-------------------------------------------------------------------------------
  function OnAoiColorChanged(value: string, colors: MuiColorInputColors, aoi_id: number)
  {
    if(!localAoiGroupProps) return;

    const attrib: IAoiAttribute | undefined = localAoiGroupProps.attributes.find(a => a.id === AOI_ADMIN_ATTRIBUTE_ID_COLOR);
    if(!attrib) return;

    let newAoiAttribute: IAoiAttribute;

    let attribValueForAoi: IAoiAttributeValue | undefined = attrib.values.find(v => v.aoi_id === aoi_id);
    if(attribValueForAoi)
    {
      // An AOI value item already exists - we update it

      const newAoiAttribValue: IAoiAttributeValue =
      {
        ...attribValueForAoi,
        value: value
      }
  
      newAoiAttribute =
      {
        ...attrib,
        values: attrib.values.map(oldValue => oldValue.aoi_id === aoi_id ? newAoiAttribValue : oldValue)
      }
      }
    else
    {
      // Create a new AOI value item and add it to the list


      const newAoiAttribValue: IAoiAttributeValue =
      {
        aoi_id: aoi_id,
        value: value
      }

      newAoiAttribute =
      {
        ...attrib,
        values: [...attrib.values,newAoiAttribValue]
      }
    }

    // Replace the old attrib with the updated version

    const newLocalAoiGroupProps: IAoiGroupProperties = 
    {
      ...localAoiGroupProps,
      attributes: localAoiGroupProps.attributes.map(oldAttrib => oldAttrib.id === AOI_ADMIN_ATTRIBUTE_ID_COLOR ? newAoiAttribute : oldAttrib)
    }

    // Set the new value
    setLocalAoiGroupProps(newLocalAoiGroupProps);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Return the local AOI attribute value for a specified AOI.
  //-------------------------------------------------------------------------------
  function GetLocalAoiAttributeValueForAnAoi(aoi_attribute_id: number, aoi_id: number): IAoiAttributeValue | undefined
  {
    if(!localAoiGroupProps) return undefined;

    const aoi_attributes: IAoiAttribute[] = localAoiGroupProps.attributes;
    if(!aoi_attributes) return undefined;

    for(let i=0; i < aoi_attributes.length; i++)
      if(aoi_attributes[i].id === aoi_attribute_id)
      {
        // Found the AOI attribute
        const value: IAoiAttributeValue | undefined = aoi_attributes[i].values.find(v => v.aoi_id === aoi_id);
        return value;
      }

    return undefined; // not found
  }

  //-------------------------------------------------------------------------------
  // Helper function (just to keep the main code cleaner).
  //-------------------------------------------------------------------------------
  function GetLocalAoiAttributeValue_IncludeInIndexMap(aoi_id: number): boolean
  {
    const valueStr: string | undefined = GetLocalAoiAttributeValueForAnAoi(AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_INDEX_MAP, aoi_id)?.value;
    if(valueStr === undefined) // Undefined - return default
      return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_INDEX_MAP;
  
    if(valueStr.toUpperCase() === 'TRUE')
      return true;
    else if(valueStr.toUpperCase() === 'FALSE')
      return false;

    // Invalid boolean value - return default
    return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_INDEX_MAP;
  }

  //-------------------------------------------------------------------------------
  // Helper function (just to keep the main code cleaner).
  //-------------------------------------------------------------------------------
  function GetLocalAoiAttributeValue_Color(aoi_id: number): string
  {
    const valueStr: string | undefined = GetLocalAoiAttributeValueForAnAoi(AOI_ADMIN_ATTRIBUTE_ID_COLOR, aoi_id)?.value;
    if(!valueStr) // Not found - return default
      return DEFAULT_AOI_COLOR;

    return valueStr;
  }

  //-------------------------------------------------------------------------------
  // Return the specified AOI attribute.
  //-------------------------------------------------------------------------------
  // function GetLocalAoiAttribute(aoi_attribute_id: number): IAoiAttribute | undefined
  // {
  //   if(!localAoiGroupProps) return undefined;
  //   return localAoiGroupProps.attributes.find(a => a.id === aoi_attribute_id);
  // }

  //-------------------------------------------------------------------------------
  // The index map border thickness text field has changed.
  //-------------------------------------------------------------------------------
  function OnIndexMapBorderThicknessChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!localAoiGroupProps) return;

    const newValueStr: string = event.target.value;
    const newValueNum: number = parseFloat(newValueStr);

    // Set the new value
    setLocalAoiGroupProps({...localAoiGroupProps, index_map_border_thickness: newValueNum });

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The index map opacity text field has changed.
  //-------------------------------------------------------------------------------
  function OnIndexMapFillOpacityChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!localAoiGroupProps) return;

    const newValueStr: string = event.target.value;
    const newValueNum: number = parseFloat(newValueStr);

    // Set the new value
    setLocalAoiGroupProps({...localAoiGroupProps, index_map_fill_opacity: newValueNum });

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // An AOI checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnIndexMapHaloCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    if(!localAoiGroupProps) return;
    
    // Set the new value
    setLocalAoiGroupProps({...localAoiGroupProps, index_map_border_halo: checked });

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Edit the attribute list.
  //-------------------------------------------------------------------------------
  function OnEditAttributeList()
  {
    store_setEditAoiAttributes(true);
  }

  //-------------------------------------------------------------------------------
  // The attribute value has changed for one of the AOIs.
  //-------------------------------------------------------------------------------
  function OnAttributeValueChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
                                   aoiAttribute: IAoiAttribute, aoi_id: number): void 
  {
    if(!localAoiGroupProps) return;

    const newValue: string = event.target.value;

    // Prep an updated attribute value

    let oldAttribValue: IAoiAttributeValue | undefined = GetLocalAoiAttributeValueForAnAoi(aoiAttribute.id,aoi_id);

    let newAttrib: IAoiAttribute;

    if(!oldAttribValue)
    {
      // No old value: create a new one

      const newAttribValue: IAoiAttributeValue = 
      {
        aoi_id: aoi_id,
        value: newValue
      }

      // Prep a new updated attribute
      newAttrib = 
      {
        ...aoiAttribute,
        values: [...aoiAttribute.values, newAttribValue]
      }
    }
    else
    {
      // Update the existing value

      const newAttribValue: IAoiAttributeValue = 
      {
        ...oldAttribValue,
        value: newValue
      }

      // Prep a new updated attribute
      newAttrib = 
      {
        ...aoiAttribute,
        values: aoiAttribute.values.map(oldAttribValue => oldAttribValue.aoi_id === aoi_id ? newAttribValue : oldAttribValue),
      }
    }

    // Prep a new updated AOI group properties

    const newLocalAoiGroupProps: IAoiGroupProperties = 
    {
      ...localAoiGroupProps,
      attributes: localAoiGroupProps.attributes.map(oldAttrib => oldAttrib.id === aoiAttribute.id ? newAttrib : oldAttrib)
    }

    // Update the local array
    setLocalAoiGroupProps(newLocalAoiGroupProps);

    // Keep track of when changes are made (so we know to save it)
    setChangesWereMade(true);
  }









  if(!store_project || !store_aoi ||!localAoiGroupProps) return null;

  return (

    <Dialog disablePortal open={store_editAoiGroupProperties===true} onClose={OnClose} maxWidth='xl' 
            PaperProps={{ sx: { minWidth: '30%', width: '40%', maxWidth: '40%', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack>
            <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
              AOI Group Properties and Attributes
            </Typography>
            <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended }}>
              These values are shared among all users of this project.
            </Typography>
          </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>

        <Stack sx={{ mt: 2 }}>

          {/* AOI Index map properties (these apply to all AOIs in the AOI group).
              Only visible when the special "AOI Index Map" is selected. */}

          {store_aoi.aoi_id === AOI_INDEX_MAP_AOI_ID
            ?
              <Stack sx={{ mb: 4 }}>

                <Typography sx={{ mb: 3, color: theme_textColorBlended, opacity: 1, fontSize: '1.2rem', fontWeight: 'bold' }}>
                  Index Map Settings
                </Typography>

                <Stack sx={{ ml: 4 }}>

                  <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>

                    {/* Border thickness */}

                    <CustomTextField name='indexMapBorderThickness' variant='standard' size='small' autoComplete='off'
                                      disabled={store_aoiGroupPropertiesIsSaving}
                                      inputProps={{ type: 'number' }} 
                                      value={localAoiGroupProps.index_map_border_thickness} 
                                      onChange={OnIndexMapBorderThicknessChanged}
                                      label={<Typography sx={{fontSize:'0.7rem',color: theme_textColorBlended+'B0'}}>Border Thickness ({INDEX_MAP_MIN_BORDER_THICKNESS}-{INDEX_MAP_MAX_BORDER_THICKNESS})</Typography>} 
                                      sx={{ p: 0, width: '125px' }}/>

                    {/* Border halo ON/OFF */}

                    <Stack direction='row' sx={{ my: 0.5, alignItems: 'center' }}>
                      <Checkbox size='small' checked={localAoiGroupProps.index_map_border_halo===true} 
                          onChange={(e,c)=>OnIndexMapHaloCheckBoxChange(e,c)}
                          sx=
                          {{ 
                            mr: 0.5, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                            '&.Mui-checked': { color: theme_textColorBlended } 
                          }} />
                      <Typography sx={{ color: theme_textColorMain, opacity: 0.7, fontSize: '0.9rem' }}>
                        Border Halo
                      </Typography>
                    </Stack>

                    {/* Fill opacity */}

                    <CustomTextField name='indexMapFillOpacity' variant='standard' size='small' autoComplete='off'
                                      inputProps={{ type: 'number' }} 
                                      value={localAoiGroupProps.index_map_fill_opacity}
                                      onChange={OnIndexMapFillOpacityChanged}
                                      label={<Typography sx={{fontSize:'0.7rem',color: theme_textColorBlended+'B0'}}>Fill Opacity (0-1)</Typography>} 
                                      sx={{ ml: 4, p: 0, width: '125px' }}/>

                  </Stack>
                </Stack>
              </Stack>
            :null
          }

          <Stack direction='row' sx={{ mb: 1, alignItems: 'center' }}>
            <Typography sx={{ color: theme_textColorBlended, fontSize: '1.2rem', fontWeight: 'bold' }}>
              AOI Attributes
            </Typography>
            <Button variant='outlined' sx={{ ml: 3, py: 0.2, px: 0.7, fontSize: '0.7rem', textTransform: 'none' }} onClick={(_)=>OnEditAttributeList()}>
              Edit Attribute List...
            </Button>
          </Stack>

            {store_project.aois.map(function(projectAoi)
            {
              // If in index map mode, show all AOIs (otherwise show only the selected AOI)
              if(store_aoi.aoi_id !== AOI_INDEX_MAP_AOI_ID && store_aoi.aoi_id !== projectAoi.aoi_id) 
                return null;

              // Get the AOI attribute for this AOI.  If there is none, create a default.
              // let aoiAttribute: IAoiAttribute | undefined = GetAoiAttribute(projectAoi.aoi_id);
              // if(!aoiAttribute)
              //   aoiAttribute = GetDefaultAoiAttribute(localAoiGroupProps.attributes);

              return (

                <Stack sx={{ my: 1, borderRadius: 2, bgcolor: theme_textColorBlended+'40' }}>
                  
                  <Typography sx={{ p: 1, color: theme_orange, fontSize: '1.0rem', fontWeight: 'bold' }}>
                    {projectAoi.aoi_name}
                  </Typography>

                  <Stack sx={{ mx: 3, mb: 2 }}>

                    {/* ADMIN attribute:  Include in index map */}

                    <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>

                      <Stack direction='row' sx={{ my: 0.5, alignItems: 'center' }}>
                        <Checkbox size='medium' checked={GetLocalAoiAttributeValue_IncludeInIndexMap(projectAoi.aoi_id)} 
                            onChange={(e,c)=>OnAoiCheckBoxChange(e,c,projectAoi.aoi_id)}
                            sx=
                            {{ 
                              mr: 0.5, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                              '&.Mui-checked': { color: theme_textColorBlended } 
                            }} />
                        <Typography sx={{ color: theme_textColorMain, opacity: 0.7 }}>
                          Include in the AOI Index Map
                        </Typography>
                      </Stack>

                      {/* ADMIN attribute:  Color */}

                      <MuiColorInputStyled variant='standard' size='small' format='hex' isAlphaHidden={true}
                                            value={GetLocalAoiAttributeValue_Color(projectAoi.aoi_id)}
                                            onChange={(v,c)=>OnAoiColorChanged(v,c,projectAoi.aoi_id)}
                                            sx={{ width: '120px' }}/>
                    </Stack>

                    {/* Attribute values for all user (non-admin) attributes */}

                    <Stack sx={{ mt: 1 }}>

                      {localAoiGroupProps.attributes.map(function(aoiAttribute)
                      {
                        // We only handle non-admin attribtues here.  Admin attributes are hard-coded up above.
                        if(aoiAttribute.is_admin === true) return null;

                        // TYPE:  NUMBER

                        if(aoiAttribute.type === 'number')
                        return (
                          <CustomTextField variant='standard' size='small' autoComplete='off'
                                            value={GetLocalAoiAttributeValueForAnAoi(aoiAttribute.id,projectAoi.aoi_id)?.value}
                                            onChange={e=>OnAttributeValueChanged(e,aoiAttribute,projectAoi.aoi_id)}
                                            inputProps={{ type: 'number' }}
                                            label=
                                            {
                                              <Stack direction='row' sx={{ alignItems: 'center' }}>
                                                <Typography sx={{ color: theme_textColorBlended }}>
                                                  {aoiAttribute.name}
                                                </Typography>
                                                {aoiAttribute.units && aoiAttribute.units.length > 0
                                                  ?
                                                    <Typography sx={{ color: theme_limeGreen, ml: 2, opacity: 0.7, fontSize: '0.8rem' }}>
                                                      ({aoiAttribute.units})
                                                    </Typography>
                                                  :null
                                                }
                                              </Stack>
                                            }
                                            sx={{ p: 0, my: 0.6 }}/>
                          )

                          // TYPE:  TEXT

                          else if(aoiAttribute.type === 'text')
                          return (
                            <CustomTextField variant='standard' size='small' autoComplete='off'
                                              value={GetLocalAoiAttributeValueForAnAoi(aoiAttribute.id,projectAoi.aoi_id)?.value}
                                              onChange={e=>OnAttributeValueChanged(e,aoiAttribute,projectAoi.aoi_id)}
                                              inputProps={{ maxLength: AOI_ATTRIBUTE_VALUE_TEXT_MAX_LENGTH }}
                                              label=
                                              {
                                                <Stack direction='row' sx={{ alignItems: 'center' }}>
                                                  <Typography sx={{ color: theme_textColorBlended }}>
                                                    {aoiAttribute.name}
                                                  </Typography>
                                                  {aoiAttribute.units && aoiAttribute.units.length > 0
                                                    ?
                                                      <Typography sx={{ color: theme_limeGreen, ml: 2, opacity: 0.7, fontSize: '0.8rem' }}>
                                                        ({aoiAttribute.units})
                                                      </Typography>
                                                    :null
                                                  }
                                                </Stack>
                                              }
                                              sx={{ p: 0, my: 0.6 }}/>
                            )
                            else
                            return (
                              <Typography sx={{ color: theme_errorRed }}>
                                [ Unsupported AOI attribute type ]
                              </Typography>
                            )
                      })}
                    </Stack>
                  </Stack>
                </Stack>
              )
            })}

        </Stack>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold' }}
                      onClick={OnAcceptChanges}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

        {/* AOI Attribute list editor popup window */}

        <EditAoiAttributeList localAoiGroupProps={localAoiGroupProps} setLocalAoiGroupProps={setLocalAoiGroupProps} 
                              setChangesWereMade={setChangesWereMade}/>

      </DialogActions>
            
    </Dialog>
  )
}

