// Identify for an AOI.

import { Paper, Stack, Table, TableBody, tableCellClasses, TableContainer, TableHead } from "@mui/material";
import { theme_bgColorLight1, theme_bgColorMain } from "../Theme";
import useStore from "../store";
import { IAoi } from "../Aois/AoiInterfaces";
import IdentifyAoiTableRow from "./IdentifyAoiTableRow";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface IdentifyAoiProps
{
  aoi: IAoi;
}



//-------------------------------------------------------------------------------
// Identify for an AOI.
//-------------------------------------------------------------------------------
const IdentifyLayerFeature = (props: IdentifyAoiProps) => 
{
  // Get needed state data from the store
  const { store_aoiGroupProperties
  } = useStore();










  // Return nothing if we have no layer or feature
  if(!props.aoi) return null;

  // Main render

  return (

    <Stack sx={{ maxHeight: '275px', overflow: 'auto' }}>

      <TableContainer component={Paper} sx={{ mt: 0.5, bgcolor: theme_bgColorMain }} elevation={0}>

        {/* <Table size="small" aria-label="simple table" sx={{ [`& .${tableCellClasses.root}`]: {borderBottom: "none"} }}> */}
        <Table size="small" aria-label="identify table" sx={{ [`& .${tableCellClasses.root}`]: {borderBottom: "1", borderColor: theme_bgColorLight1 + '8F'} }}>

          <TableHead>
          </TableHead>

          <TableBody>

            {store_aoiGroupProperties?.attributes.map(function(aoiAttribute)
            {
              // Ignore admin attributes
              if(aoiAttribute.is_admin) return null;

              return (
                <IdentifyAoiTableRow key={aoiAttribute.id} aoiAttribute={aoiAttribute} aoi={props.aoi} />
              )
            })}

          </TableBody>

        </Table>
      </TableContainer>

    </Stack>

  )
}


export default IdentifyLayerFeature;
