import { TableRow, TableCell, Stack, Typography, Link } from "@mui/material";
import { theme_bgColorMain, theme_textColorBlended, theme_limeGreen, theme_orange } from "../Theme";
import { IAoi, IAoiAttribute } from "../Aois/AoiInterfaces";
import { FriendlyNumber, IsURL } from "../Globals";
import { GetAoiAttributeValueForAnAoi } from "../Aois/AoiOps";
import AoiAttributeHelp from "../Components/AoiAttributeHelp";


interface IdentifyAoiTableRowProps
{
  aoiAttribute: IAoiAttribute;
  aoi: IAoi;
}

//-------------------------------------------------------------------------------
// Identify AOI TableRow render component.
//-------------------------------------------------------------------------------
const IdentifyAoiTableRow = (props: IdentifyAoiTableRowProps) => 
{
  // Get needed state data from the store
  // const { 
  // } = useStore();

  
  //const attrib: IVectorLayerAttribute | undefined = GetVectorLayerAttribute(props.layer, props.attribute_name);
  //if(!attrib) return null;







  //-------------------------------------------------------------------------------
  // Render a single attribute value (renders appropriately given the attribute's data type).
  //-------------------------------------------------------------------------------
  function RenderAttribValue()
  {
    return RenderAoiAttributeValue(props.aoiAttribute, GetAoiAttributeValueForAnAoi(props.aoiAttribute.id, props.aoi.aoi_id)?.value);
  }



  // Option to skip attributes with empty values.
  // if(store_project && store_project.user_settings && store_project.user_settings.identify_showEmptyValues === false && 
  //    GetAoiAttributeValueForAnAoi(props.aoiAttribute.id, props.aoi.aoi_id)?.value === undefined || 
  //    GetAoiAttributeValueForAnAoi(props.aoiAttribute.id, props.aoi.aoi_id)?.value.length === 0)
  //   return null;

  // Main render

  return (
    
    <TableRow sx={{ paddingTop: '0px', paddingBottom: '0px' }}>

      <TableCell sx={{ bgcolor: theme_bgColorMain, p: 0, pl: 0.4, pr: 0.4 }}>
        <Stack direction='row' sx={{ alignItems: 'center' }}>
          <AoiAttributeHelp attribute={props.aoiAttribute} />
          <Typography sx={{ ml: '3px', color: theme_textColorBlended, fontSize: '0.7rem' }}>
            {props.aoiAttribute.name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ bgcolor: theme_bgColorMain, color: theme_limeGreen, fontSize: '0.7rem', p: 0, pl: 0.2, pr: 0.2 }}>
        <RenderAttribValue/>
      </TableCell>

    </TableRow>
  )
}

export default IdentifyAoiTableRow;



//-------------------------------------------------------------------------------
// Render the specified attribute value.
//-------------------------------------------------------------------------------
function RenderAoiAttributeValue(aoiAttribute: IAoiAttribute, attribute_value: any): any
{
  const valueStr: string = attribute_value;

  // Numerical value

  if(aoiAttribute.type === 'number') // Number
  {
    const valueNum: number = Number.parseFloat(valueStr);
    const decimalPlaces = 2;

    return (
      <Stack direction='row' sx={{ alignItems: 'center' }}>

        {/* Attribute value */}

        <Typography sx={{ color: theme_limeGreen, fontSize: '0.7rem' }}>
          {Number.isNaN(valueNum) 
            ?
              '-' // Show a dash instead of 'NaN'
            :
              FriendlyNumber(valueNum, decimalPlaces !== undefined ? decimalPlaces : 0)
          }
        </Typography>

        {/* Units (optional) */}

        {aoiAttribute.units !== undefined && aoiAttribute.units.length > 0
          ?
            <Typography sx={{ ml: 0.5, fontSize: '0.6rem', color: theme_orange+'A0', textWrap: 'nowrap' }}>
              {aoiAttribute.units}
            </Typography>
          :null
        }
      </Stack>
    )
  }

  // Text

  else
  return (
    IsURL(valueStr)
      ?
        <Link href={valueStr} target="_blank">
          {valueStr}
        </Link>
      :
        valueStr
  )
}
