// Edit AOI attribute list

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import useStore from "../store";
import CloseIcon from '@mui/icons-material/Close';
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_errorRed } from "../Theme";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { IAoiAttribute, IAoiGroupProperties, TAoiAttributeType } from "./AoiInterfaces";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CustomTextField } from "../LayerLibrary/EditLayer/EditLayer";
import { CustomSelect } from "../LayerLibrary/EditLayer/EditLayerStyle";
import { ToastNotification } from "../ToastNotifications";

const USER_AOI_ATTRIBUTES_STARTING_ID = 100;  // user-defined attributes start with this ID and go up (all admin attributes IDs should be below this number)

const AOI_ATTRIBUTE_NAME_MAX_LENGTH = 30;
const AOI_ATTRIBUTE_UNITS_MAX_LENGTH = 20;
const AOI_ATTRIBUTE_DESCRIPTION_MAX_LENGTH = 100;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditAoiAttributeListProps
{
  localAoiGroupProps: IAoiGroupProperties|undefined;
  setLocalAoiGroupProps: any;
  setChangesWereMade: any;
}

//-------------------------------------------------------------------------------
// Edit AOI attribute list
//-------------------------------------------------------------------------------
export function EditAoiAttributeList(props: EditAoiAttributeListProps) 
{
  // Get needed state data from the store
  const { store_editAoiAttributes, store_setEditAoiAttributes, 
          store_project, store_aoi
        } = useStore();


  const [localAoiAttributes, setLocalAoiAttributes] = useState<IAoiAttribute[]>([]);
  const [localChangesWereMade, setLocalChangesWereMade] = useState<boolean>(false);









  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Initializes the local UI with values from the state store
    if(store_editAoiAttributes === true && props.localAoiGroupProps)
      setLocalAoiAttributes(props.localAoiGroupProps.attributes);

  }, [store_editAoiAttributes]);
  
  //-------------------------------------------------------------------------------
  // Close the window (without saving changes).
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    store_setEditAoiAttributes(false);
    setLocalAoiAttributes([]);
    setLocalChangesWereMade(false);
  }

  //-------------------------------------------------------------------------------
  // Save changes and close.
  //-------------------------------------------------------------------------------
  function OnAcceptChanges()
  {
      // Validation

    if(!localAoiAttributes || !props.localAoiGroupProps)
      return;

    // Attribute names cannot be empty

    for(let i=0; i < localAoiAttributes.length; i++)
      if(localAoiAttributes[i].name.trim().length === 0)
      {
        ToastNotification('error', `Attribute names cannot be empty`);
        return;
      }

    // Make sure there are no attributes with the same name

    for(let i=0; i < localAoiAttributes.length; i++)
      for(let j=0; j < localAoiAttributes.length; j++)
        if(i!==j && localAoiAttributes[i].name.toUpperCase() === localAoiAttributes[j].name.toUpperCase())
        {
          ToastNotification('error', `Found attributes with the same name (attribute names must be unique)`);
          return;
        }

    // If changes were made, save the changes to the local copy of the AOI group properties
    // (that is passed in through param mechanism).

    if(localChangesWereMade === true)
    {
      // Replace the attribute list with the new updated version
      const newLocalAoiGroupProps: IAoiGroupProperties = 
      {
        ...props.localAoiGroupProps,
        attributes: localAoiAttributes
      }

      props.setLocalAoiGroupProps(newLocalAoiGroupProps);
      props.setChangesWereMade(true);
    }

    // Close the window
    OnClose();
  }

  //-------------------------------------------------------------------------------
  // Add a new attribute to the list.
  //-------------------------------------------------------------------------------
  function OnAddNewAttribute()
  {
    const newAoiAttribute: IAoiAttribute = 
    {
      id: GetAoiAttributeNextID(localAoiAttributes),
      name: "",
      type: "text",
      is_admin: false,
      units: '',
      description: '',
      values: []
    }

    setLocalAoiAttributes([...localAoiAttributes,newAoiAttribute]);

    // Keep track of when changes are made (so we know to save it)
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Deletes an attribute from the list.
  //-------------------------------------------------------------------------------
  function OnDeleteAttribute(attribToDelete: IAoiAttribute)
  {
    setLocalAoiAttributes(localAoiAttributes.filter(a => a.id !== attribToDelete.id));

    // Keep track of when changes are made (so we know to save it)
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The attribute name has changed.
  //-------------------------------------------------------------------------------
  function OnAttributeNameChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, attrib: IAoiAttribute): void 
  {
    const newValue: string = event.target.value;

    // Prep a new update attribute
    const newAttrib: IAoiAttribute = 
    {
      ...attrib,
      name: newValue,
    }

    // Update the local array
    setLocalAoiAttributes(localAoiAttributes.map(oldAttrib => oldAttrib.id === attrib.id ? newAttrib : oldAttrib));

    // Keep track of when changes are made (so we know to save it)
    setLocalChangesWereMade(true);
  }    

  //-------------------------------------------------------------------------------
  // The attribute type combo box was changed.
  //-------------------------------------------------------------------------------
  const OnAttributeTypeChanged = (event: SelectChangeEvent<unknown>, child: ReactNode, attrib: IAoiAttribute) => 
  {
    const newValue: TAoiAttributeType = event.target.value as TAoiAttributeType;

    // Prep a new updated attribute
    const newAttrib: IAoiAttribute = 
    {
      ...attrib,
      type: newValue,
    }

    // Update the local array
    setLocalAoiAttributes(localAoiAttributes.map(oldAttrib => oldAttrib.id === attrib.id ? newAttrib : oldAttrib));

    // Keep track of when changes are made (so we know to save it)
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The attribute units has changed.
  //-------------------------------------------------------------------------------
  function OnAttributeUnitsChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, attrib: IAoiAttribute): void 
  {
    const newValue: string = event.target.value;

    // Prep a new update attribute
    const newAttrib: IAoiAttribute = 
    {
      ...attrib,
      units: newValue,
    }

    // Update the local array
    setLocalAoiAttributes(localAoiAttributes.map(oldAttrib => oldAttrib.id === attrib.id ? newAttrib : oldAttrib));

    // Keep track of when changes are made (so we know to save it)
    setLocalChangesWereMade(true);
  }    

  //-------------------------------------------------------------------------------
  // The attribute description has changed.
  //-------------------------------------------------------------------------------
  function OnAttributeDescriptionChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, attrib: IAoiAttribute): void 
  {
    const newValue: string = event.target.value;

    // Prep a new update attribute
    const newAttrib: IAoiAttribute = 
    {
      ...attrib,
      description: newValue,
    }

    // Update the local array
    setLocalAoiAttributes(localAoiAttributes.map(oldAttrib => oldAttrib.id === attrib.id ? newAttrib : oldAttrib));

    // Keep track of when changes are made (so we know to save it)
    setLocalChangesWereMade(true);
  }    












  if(!store_project || !store_aoi) return null;

  return (

    <Dialog disablePortal open={store_editAoiAttributes===true} onClose={OnClose} maxWidth='xl' 
            PaperProps={{ sx: { minWidth: '30%', width: '40%', maxWidth: '40%', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack>
            <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
              AOI Attributes
            </Typography>
            <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended }}>
              This attribute list applies to all AOIs that are part of this project and is shared among all its users.
            </Typography>
          </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>

        <Stack sx={{ mt: 2 }}>

          {localAoiAttributes.length === 2  // Hardcoded for now - there are currently only 2 admin attributes
            ?
              <Typography sx={{ color: theme_errorRed, opacity: 0.6, mt: 2, mb: 1 }}>
                There are currently no AOI attributes defined for this project.
              </Typography>
            :null
          }

          {localAoiAttributes.map(aoiAttribute =>

            aoiAttribute.is_admin === false // Admin attributes are not editable
              ?
                <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between', my: 1, p: 1, 
                                             bgcolor: theme_bgColorLight1+'45', borderRadius: 2, boxShadow: 2 }}>

                  <Stack direction='column' sx={{ width: '100%' }}>

                    {/* Attribute name, type, and units */}
                    
                    <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>

                      {/* Attribute name */}

                      <CustomTextField variant='standard' size='small' autoComplete='off'
                                      value={aoiAttribute.name} onChange={e=>OnAttributeNameChanged(e,aoiAttribute)}
                                      inputProps={{ maxLength: AOI_ATTRIBUTE_NAME_MAX_LENGTH }}
                                      label={<Typography sx={{ color: theme_textColorBlended }}>Attribute Name</Typography>}
                                      sx={{ p:0 }}/>

                      {/* Attribute type combo box */}

                      <Stack sx={{ mr: 0 }}>

                        <Typography sx={{ mt: '0px', fontSize: '0.8rem', color: theme_textColorBlended+'B0' }}>
                          Type
                        </Typography>

                        <CustomSelect variant='standard' size='small'
                                      value={aoiAttribute.type} onChange={(e,c)=>OnAttributeTypeChanged(e,c,aoiAttribute)}
                                      sx={{ width: '115px', mt: '0px', mb: 0, fontSize: '1.0rem' }}>
                          <MenuItem key='text' value='text'>
                            Text
                          </MenuItem>
                          <MenuItem key='number' value='number'>
                            Number
                          </MenuItem>
                        </CustomSelect>

                      </Stack>

                      {/* Units */}

                      <CustomTextField variant='standard' size='small' autoComplete='off'
                                      value={aoiAttribute.units} onChange={e=>OnAttributeUnitsChanged(e,aoiAttribute)}
                                      inputProps={{ maxLength: AOI_ATTRIBUTE_UNITS_MAX_LENGTH }}
                                      label={<Typography sx={{ color: theme_textColorBlended }}>Units</Typography>}
                                      sx={{ p: 0 }}/>

                    </Stack>

                    {/* Attribute description */}

                    <CustomTextField variant='standard' size='small' autoComplete='off'
                                      value={aoiAttribute.description} onChange={e=>OnAttributeDescriptionChanged(e,aoiAttribute)}
                                      inputProps={{ maxLength: AOI_ATTRIBUTE_DESCRIPTION_MAX_LENGTH }}
                                      label={<Typography sx={{ color: theme_textColorBlended }}>Description (optional)</Typography>}
                                      sx={{ p:0, mt: 1.5 }}/>

                  </Stack>

                  {/* Delete attribute button */}

                  <IconButton size="small" onClick={_=>OnDeleteAttribute(aoiAttribute)}
                              sx={{ ml: 2, width: '28px', height: '28px'}}>
                    <DeleteForeverIcon sx={{ opacity: 0.8, width: '25px', height: '25px', color: theme_textColorBlended }}/>
                  </IconButton>

                </Stack>
              :null
          )}

          <div>
            <Button variant='outlined' sx={{ mt: 3, textTransform: 'none' }} 
                    onClick={_=>OnAddNewAttribute()}>
              Add a new attribute
            </Button>
          </div>








        </Stack>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold' }}
                      onClick={_=>OnAcceptChanges()}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>
  )
}



//-------------------------------------------------------------------------------
// Return the next ID for a new AOI attribute.
//-------------------------------------------------------------------------------
export function GetAoiAttributeNextID(attributeList: IAoiAttribute[]): number
{
  let nextID: number = USER_AOI_ATTRIBUTES_STARTING_ID;

  for(let i=0; i < attributeList.length; i++)
    if(attributeList[i].id >= nextID)
      nextID = attributeList[i].id + 1;

  return nextID;
}

//-------------------------------------------------------------------------------
// Returns a default for a user-default AOI attribute.
//-------------------------------------------------------------------------------
// export function GetDefaultAoiAttribute(aoiAttribList: IAoiAttribute[]): IAoiAttribute
// {
//   const newAoiAttrib: IAoiAttribute = 
//   {
//     id: GetAoiAttributeNextID(aoiAttribList),
//     name: '',
//     type: 'text',
//     is_admin: false,
//     units: '',
//     values: []
//   }

//   return newAoiAttrib;
// }

