// User account helper functions

import UserPool from './UserPool';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useStore } from '../store';
import { Typography, Link } from '@mui/material';
import { AutoSave } from '../App';
import { RemoveAllLayersAndSourcesFromMap } from '../Layers/LayerOps';
import { RemoveAoiLayersFromMap } from '../Aois/AoiOps';
import { CallServer, CallServerResults } from '../CallServer';
import Debug from '../Debug';
import { ToastNotification } from '../ToastNotifications';
import { RemoveDrawControlFromMap } from '../Map/MapOps';
import { RemoveParcelSelectionLayerFromMap } from '../Parcels/ParcelOps';
import { RemoveProjectBoundaryLayerFromMap } from '../Projects/ProjectOps';


export interface IUserInfo
{
  id: string;   // UUID
  first_name: string;
  last_name: string;
  organizations: IOrganization[];
}

export interface IOrganization
{
  id: number;
  name: string;
  settings: IOrganizationSettings;
}

export interface IOrganizationSettings
{
  allow_project_sharing: boolean;
  show_boundary: boolean;
}

const CONST_NAME_MAX_LENGTH = 40; // first or last
const CONST_EMAIL_MAX_LENGTH = 80;

export const COGNITO_PWD_RULE_SPECIAL_CHARS = [ '^', '$', '*', '.', '[', ']', '{', '}', '(', ')', '?', '-', '"', '!', '@', '#', '%', '&', '/', '\', ',',', '>', '<', '\'', ':', ';', '|', '_', '~', '`', '+', '=' ];

//-------------------------------------------------------------------------------
// Validate the previously logged in user.
//-------------------------------------------------------------------------------
export function ValidatePreviousLogin(): boolean
{
  const prevLoginUsername = window.localStorage.getItem('username');
  if(prevLoginUsername && prevLoginUsername.length > 0)
  {
    // NOTE: The Cognito JS lib automatically stores data in LocalStorage 
    //       during login, and 'getCurrentUser' looks there and retrievs 
    //       the previously-logged-in user.

    const user = UserPool.getCurrentUser();
    if(user) 
    {
      user.getSession(async (err: Error | null, session : CognitoUserSession | null) => 
      {
        if (err) 
        {
          Debug.log('UserOps.ValidatePreviousLogin> Error retrieving user session: ' + err.message);
          return false;
        }
      
        if (session && session.isValid()) 
        {
          Debug.log("UserOps.ValidatePreviousLogin> Previous user session is valid")
          useStore.getState().store_setUsername(prevLoginUsername);
          useStore.getState().store_setAccessToken(session.getAccessToken().getJwtToken())
          
          // NOTE: For now we get the user's first and last name from the Cognito token.
          //       This works because the 'name' was created as part of the current user pool in use.
          //       In the future that name may not be there, so we'd need to get the user info
          //       from a direct API call after login.
          //       (currently the user's first/last name is only used to place initials in the
          //        top/right circle menu button)

          // const name: string = session.getIdToken().decodePayload().name;
          // const splitName = name.split(' ');
          // useStore.getState().store_setFirstName(splitName[0]);
          // useStore.getState().store_setLastName(splitName[1]);

          // Load the user info (user id, first name, last name, organization list)

          if(await LoadUserInfo() === false)
          {
            // Cognito auth worked, but we can't login without the user info
            useStore.getState().store_setUsername('');
            useStore.getState().store_setAccessToken('')
            Debug.log('UserOps.ValidatePreviousLogin> Failed to load user info');
            return;
          }

          useStore.getState().store_setIsLoggedIn(true);
          useStore.getState().store_setLogInDate(new Date());

          return true;  // Valid session
        }
        else
        {
          Debug.log('UserOps.ValidatePreviousLogin> Previous user session is NOT valid');
          
          // Clear out the username from LocalStorage so any further attempts will not keep 
          // trying to validate what we now know is a bad/expired previous session.
          window.localStorage.setItem('username', '');
          return false;
        }
      });
    }
    else 
    {
      Debug.log('UserOps.ValidatePreviousLogin> No previous user session detected');
      return false;
    }
  }

  return false;
}

//-------------------------------------------------------------------------------
// Log the user out.
//-------------------------------------------------------------------------------
export async function PerformLogout()
{
  Debug.log("\nUserOps.PerformLogout> \n")

  await AutoSave();

  RemoveAllLayersAndSourcesFromMap();
  RemoveDrawControlFromMap();
  RemoveAoiLayersFromMap();
  RemoveProjectBoundaryLayerFromMap();
  RemoveParcelSelectionLayerFromMap();

  // Sign out of Cognito
  UserPool.getCurrentUser()?.signOut();

  // Sign out of the state store
  useStore.getState().store_reset();

  // Sign out of LocalStorage
  window.localStorage.removeItem('username');
}

//-------------------------------------------------------------------------------
// Validate first name.
//-------------------------------------------------------------------------------
export function ValidateFirstName(name: string) : string
{
  if(!name || name.length <= 0)
    return 'The first name cannot be empty';

  if(name.length > CONST_NAME_MAX_LENGTH)
    return `The first name is too long (max ${CONST_NAME_MAX_LENGTH} characters)`;

  return '';  // validated
}

//-------------------------------------------------------------------------------
// Validate last name.
//-------------------------------------------------------------------------------
export function ValidateLastName(name: string) : string
{
  if(!name || name.length <= 0)
    return 'The last name cannot be empty';

  if(name.length > CONST_NAME_MAX_LENGTH)
    return `The last name is too long (max ${CONST_NAME_MAX_LENGTH} characters)`;

  return '';  // validated
}

//-------------------------------------------------------------------------------
// Validate email.
// Returns '' if validated, otherwise returns a validation error message.
//-------------------------------------------------------------------------------
export function ValidateEmail(value: string) : string
{
  if(!value || value.length <= 0)
    return 'The email must not be empty';

  if(value.length > CONST_EMAIL_MAX_LENGTH)
    return `The email is too long (max ${CONST_EMAIL_MAX_LENGTH} characters)`;

  // Validate based on the RFC 5322 format

  // eslint-disable-next-line
  let regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
  if(!regex.test(value))
    return 'The email address is not valid';

  // NOTE: Regex is from https://stackabuse.com/validate-email-addresses-with-regular-expressions-in-javascript/
  //
  // Extended version that convers a few more edge cases:
  // (?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])

  return '';  // validated
}

//-------------------------------------------------------------------------------
// Validate password.
//-------------------------------------------------------------------------------
export function ValidatePassword(value: string) : string
{
  // Cognito password rules:
  //
  // Password length: 8 - 256 characters
  // Contains at least 1 number
  // Contains at least 1 uppercase letter
  // Contains at least 1 lowercase letter
  // Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =)

  
  if(!value || value.length < 8)
    return 'The password must be at least 8 characters';

  if(value.length > 256)
    return `The password is too long (max ${256} characters)`;

  let numFound = false;
  let upperCaseFound = false;
  let lowerCaseFound = false;
  let specialCharFound = false;

  for(let i=0; i < value.length; i++)
  {
    const c = value.charAt(i);

    if(c >= 'a' && c <= 'z')
      lowerCaseFound = true;
    if(c >= 'A' && c <= 'Z')
      upperCaseFound = true;
    if(c >= '0' && c <= '9')
      numFound = true;
    if(COGNITO_PWD_RULE_SPECIAL_CHARS.includes(c))
      specialCharFound = true;
  }

  if(!lowerCaseFound)
    return 'The password must contain at least 1 lower case letter';
  if(!upperCaseFound)
    return 'The password must contain at least 1 upper case letter';
  if(!numFound)
    return 'The password must contain at least 1 number';
  if(!specialCharFound)
    return 'The password must contain at least 1 special character';

  return '';  // validated
}

//-------------------------------------------------------------------------------
// Shows a Copyright message.
//-------------------------------------------------------------------------------
export function Copyright(props: any) 
{
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://stratifyx.com/">
        StratifyX
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

//-------------------------------------------------------------------------------
// Load user info for the logged-in user.
//-------------------------------------------------------------------------------
export async function LoadUserInfo(): Promise<boolean>
{
  const username: string = useStore.getState().store_username;
  if(!username || username.length === 0)
  {
    Debug.log(`UserOps.LoadUserInfo> Invalid username.`);
    return false;
  }

  // Call the server to get the data

  const server = new CallServer();

  // This is here to support proxy login by an admin into another account.
  // If authorized, it will return the info of this other user, not the user that authenticated.
  // For the rest of the session, the auth token of the admin will be used, but the "identity" will
  // be that of the effective user.
  
  server.AddHeader("effective-username", username); // This will be the user the info is returned for (as opposed to the user in the auth tokens)
  //server.AddHeader("effective-username", 'trevor.fox@stratifyx.com');

  //useStore.getState().store_setUserInfoIsLoading(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('get', '/user');

  //useStore.getState().store_setUserInfoIsLoading(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    Debug.log('UserOps.LoadUserInfo> API server call SUCCESS');
    //Debug.log('UserOps.LoadUserInfo> SUCCESS! data=' + JSON.stringify(result.data));

    const userInfo: IUserInfo | undefined = result.data;
    if(!userInfo)
    {
      ToastNotification('error', "Unable to log in")
      Debug.error('UserOps.LoadUserInfo> Received invalid data');
      return false;
    }

    // Update the state store
    useStore.getState().store_setUserInfo(userInfo);

    // Success
    Debug.log(`UserOps.LoadUserInfo> User info loaded.`);
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to log in")
    Debug.error('UserOps.LoadUserInfo> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Initiate a password reset (emails the user a password reset code).
//-------------------------------------------------------------------------------
export async function ResetPassword_Step1(email: string): Promise<boolean>
{
  // Call the server

  const server = new CallServer(false);  // no auth (user is not logged in)
  server.Add('email_address', email);

  useStore.getState().store_setPwdResetRunningStep1(true); // Signal the UI the process has started

  const result = await server.Call('post', '/forgot_password');

  useStore.getState().store_setPwdResetRunningStep1(false); // Signal the UI that we are done

  if(result.success)
  {
    Debug.log('UserOps.ResetPassword_Step1> API server call SUCCESS');
    //Debug.log('UserOps.ResetPassword_Step1> SUCCESS! data=' + JSON.stringify(result.data));

    const userInfo: IUserInfo | undefined = result.data;
    if(!userInfo)
    {
      ToastNotification('error', "Unable to initiate password reset")
      Debug.error('UserOps.ResetPassword_Step1> Received invalid data');
      return false;
    }

    // Update the state store
    useStore.getState().store_setUserInfo(userInfo);

    // Success
    Debug.log(`UserOps.ResetPassword_Step1> Password reset initiated.`);
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to initiate password reset")
    Debug.error('UserOps.ResetPassword_Step1> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Confirm the user-supplied password reset code, and if confirmed, change the password.
//-------------------------------------------------------------------------------
export async function ResetPassword_Step2(email: string, code: string, newPassword: string): Promise<boolean>
{
  // Call the server

  const server = new CallServer(false);  // no auth (user is not logged in)
  server.Add('email_address', email);
  server.Add('confirmation_code', code);
  server.Add('new_password', newPassword);

  useStore.getState().store_setPwdResetRunningStep2(true); // Signal the UI the process has started

  const result = await server.Call('post', '/confirm_forgot_password');

  useStore.getState().store_setPwdResetRunningStep2(false); // Signal the UI that we are done

  if(result.success)
  {
    Debug.log('UserOps.ResetPassword_Step2> API server call SUCCESS');
    //Debug.log('UserOps.ResetPassword_Step2> SUCCESS! data=' + JSON.stringify(result.data));

    const userInfo: IUserInfo | undefined = result.data;
    if(!userInfo)
    {
      ToastNotification('error', "Unable to complete the password reset process")
      Debug.error('UserOps.ResetPassword_Step2> Received invalid data');
      return false;
    }

    // Update the state store
    useStore.getState().store_setUserInfo(userInfo);

    // Success
    Debug.log(`UserOps.ResetPassword_Step2> Password reset initiated.`);
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to complete the password reset process")
    Debug.error('UserOps.ResetPassword_Step2> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Returns the full org details for the specified org id.
//-------------------------------------------------------------------------------
export function GetOrg(organization_id: number | undefined): IOrganization | undefined
{
  if(!organization_id) return undefined;
  
  const userInfo: IUserInfo | null = useStore.getState().store_userInfo;
  if(!userInfo) return undefined;

  return userInfo.organizations.find(org => org.id === organization_id);
}

//-------------------------------------------------------------------------------
// Returns a single string listing all the organizations the logged in user is 
// part of.
//-------------------------------------------------------------------------------
// export function GetAllOrgsStr(): String | undefined
// {
//   const userInfo: IUserInfo | null = useStore.getState().store_userInfo;
//   if(!userInfo) return undefined;

//   let str = '';
//   for(let i=0; i < userInfo.organizations.length; i++)
//   {
//     str += userInfo.organizations[i].name;
//     if(i < userInfo.organizations.length-1)
//     str += ', ';
//   }

//   return str;
// }