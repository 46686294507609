// Identify operations

import Debug from "../Debug";
import { ILayer } from "../Layers/LayerInterfaces";
import { GetActiveIdentifiableLayers, GetActiveGeoserverLayer, GetLayerByName } from "../Layers/LayerOps";
import useStore from "../store";
import { ToastNotification } from "../ToastNotifications";
import { IIdentifyForLayer, IIdentifyFeature, IGeoserverFeature, IIdentifyData } from "./IdentifyInterfaces";
import { FeatureCollection } from "geojson";
import { CallServer } from "../CallServer";
import { PARCEL_LAYER_NAME } from "../Globals";
import { AOI_INDEX_MAP_AOI_ID } from "../Aois/Aois";
import { IAoi, IAoiAttributeValue } from "../Aois/AoiInterfaces";
import { IsPointInsidePolygon } from "../GisOps";
import { GetAoiAttributeValueForAnAoi, IsAoiInIndexMap } from "../Aois/AoiOps";
import { AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_INDEX_MAP } from "../Aois/EditAoiGroupProperties";


//-------------------------------------------------------------------------------
// Handles map clicks for Feature Identify.
//-------------------------------------------------------------------------------
export async function MapFeatureIdentifyClick(clickLongitude: number, clickLatitude: number)
{
  Debug.log('IdentifyOps.MapFeatureIdentifyClick> ');

  useStore.getState().store_setIdentify(undefined);

  const identifyData: IIdentifyData = 
  {
    latitude: clickLatitude,
    longitude: clickLongitude,
    identifyLayers: [],
    identifyAOIs: [],
    isLoading: false,
  }

  const activeGeoserverRasterLayers : ILayer[] = GetActiveIdentifiableLayers();
  if(activeGeoserverRasterLayers.length > 0)
  {
    const result = await GeoserverIdentify(activeGeoserverRasterLayers, clickLongitude, clickLatitude);

    if(result.failed)
    {
      ToastNotification('error', 'Unable to identify clicked feature');
      return;
    }

    if(result.data && result.data.features.length > 0)  // We've received at least 1 feature - transfer the data
      for(let i=0; i < result.data.features.length; i++)
        ProcessGeoserverIdentifyFeature(result.data.features[i] as IGeoserverFeature, identifyData.identifyLayers);
  }

  // Check if the click is inside the active AOI (or all AOIs if in index map mode)

  // If we're in index map mode, add AOI-identify for any AOIs that were clicked
  
  let checkAoiList: IAoi[] = [];
  const store_aoi: IAoi | null = useStore.getState().store_aoi;
  if(store_aoi)
  {
    if(store_aoi.aoi_id === AOI_INDEX_MAP_AOI_ID)
    {
      // Index map mode - add all AOIs that are part of the index map

      const store_indexMapAois = useStore.getState().store_indexMapAois;

      for(let i=0; i < store_indexMapAois.length; i++)
        if(IsAoiInIndexMap(store_indexMapAois[i].aoi_id))
          checkAoiList.push(store_indexMapAois[i])
    }
    else // Add only the active AOI
      checkAoiList.push(store_aoi);
  }

  for(let i=0; i < checkAoiList.length; i++)
  {
    const clickedInside: boolean = IsPointInsidePolygon(clickLongitude, clickLatitude, checkAoiList[i].geom);
    if(clickedInside === true)
      identifyData.identifyAOIs.push(checkAoiList[i])
  }

  // Update the state store
  useStore.getState().store_setIdentify(identifyData);
}


//-------------------------------------------------------------------------------
// API call to get a list of features for the specified layers at a specific point.
// NOTE: The API wraps a call to Geoserver WMS "GetFeatureInfo"
//-------------------------------------------------------------------------------
export async function GeoserverIdentify(layers: ILayer[], longitude: number, latitude: number) : Promise<GeoserverFeaturesQueryResults>
{
  const store_project = useStore.getState().store_project;
  if(!store_project)
    return new GeoserverFeaturesQueryResults(false, 'invalid project', null);

  // If the user has enabled the "ShowOnlyParcelUserAttributes" project settings, and one of 
  // the active layers is the parcels layer, send the list of attributes we want (for all other 
  // layers it sends back all attributes).

  let parcelAttributes: string[] = [];
  if(store_project.user_settings.identify_showOnlyParcelUserAttributes === true)
  {
    const parcelLayer: ILayer | undefined = layers.find(l => l.name === PARCEL_LAYER_NAME);
    if(parcelLayer)
      for(let i=0; i < store_project.user_settings.parcel_attribute_list.length; i++)
        parcelAttributes.push(store_project.user_settings.parcel_attribute_list[i].name);
  }

  // Build the comma-delimited list of layers, and the aoi filter json

  let geoserverTypeNameStr: string = '';
  const filterJsonArray = [];
  let layersWithAoiFiltersCount: number = 0;

  for(let i=0; i < layers.length; i++)
  {
    const layer: ILayer = layers[i];

    geoserverTypeNameStr += layer.name;
    if(i < layers.length-1) geoserverTypeNameStr += ',';

    // The Identify interface is not consistent in how it takes in filter data
    // for impact layers.  To determine if a layer is an "HBV impact layer",
    // we look if 'nrr_id' and 'impact_id' are defined.
    //
    // For the main HBV layer the format is:
    //
    //   [{"layer":"lmp", "aoi_ids":[1,2]}]
    //
    // For HBV impact layers, the filter format is:
    //
    //   [{"layer" : "impact_layer", "impact_list" : [{"aoi_id":1, "nrr_id":-7, "impact_id":-12}]}]

    const isHbvImpactLayer: boolean = layer.nrr_id !== undefined && layer.impact_id !== undefined;
    let layerFilter: any = null;

    if(isHbvImpactLayer)
    {
      // HBV impact layer
      //
      // format:  [{"layer" : "impact_layer", "impact_list" : [{"aoi_id":1, "nrr_id":-7, "impact_id":-12}]}]

      layerFilter = { "layer": layer.name, "impact_list": [] };

      for(let j=0; j < layer.aoi_id_filters.length; j++)
      {
        const aoiFilter: any = 
        {
          aoi_id: layer.aoi_id_filters[j],
          nrr_id: layer.nrr_id,
          impact_id: layer.impact_id
        }
        layerFilter['impact_list'].push(aoiFilter);
      }

      if(layer.aoi_id_filters.length > 0)
        layersWithAoiFiltersCount++;
    }
    else
    {
      // Normal layer or HBV main layer
      //
      // format:  [{"layer":"lmp", "aoi_ids":[1,2]}]

      layerFilter = { "layer": layer.name };

      if(layer.aoi_id_filters.length > 0)
      {
        layerFilter['aoi_ids'] = layer.aoi_id_filters;
        layersWithAoiFiltersCount++;
      }
    }

    filterJsonArray.push(layerFilter);
  }

  const server = new CallServer(true);
  server.Add("layers", geoserverTypeNameStr);
  server.Add("lng", longitude);
  server.Add("lat", latitude);
  server.Add("remove_geo", "true");
  if(layersWithAoiFiltersCount > 0)
    server.Add("filter", filterJsonArray);
  if(store_project.boundary)
    server.Add("boundary_id", store_project.boundary.boundary_id);
  if(store_project && store_project.project_id)
    server.Add("project_id", store_project.project_id); // used to determine project boundary
  if(parcelAttributes.length > 0)
    server.Add("parcel_attributes", JSON.stringify(parcelAttributes));

  useStore.getState().store_setIdentifyIsLoading(true);

  const result = await server.Call('get', '/geoserver_featureinfo');

  useStore.getState().store_setIdentifyIsLoading(false);

  if(result.success)
  {
    Debug.log('SUCCESS! data=' + JSON.stringify(result.data));

    // Success
    Debug.log(`GeoserverOps.GeoserverIdentify> Received data.`);
    return new GeoserverFeaturesQueryResults(true, '', result.data as FeatureCollection);
  }
  else
  {
    Debug.error('FAILURE! err=' + result.errorCode + ' - ' + result.errorMessage);

    let errStr;
    if(result.errorCode > 0) errStr = 'code ' + result.errorCode + ' - ' + result.errorMessage;
    else errStr = result.errorMessage;

    return new GeoserverFeaturesQueryResults(false, errStr, null);
  }
}

//-------------------------------------------------------------------------------
// Process a single GeoJSON Feature that was returned by a Geoserver identify operation.
//-------------------------------------------------------------------------------
function ProcessGeoserverIdentifyFeature(newGeoJSONFeature: IGeoserverFeature, outputIdentifyLayers: IIdentifyForLayer[])
{
  // NOTE:  We get data from 2 sources - Mapbox itself, or Geoserver requests.
  //
  //        For Mapbox data, the id contains the layer name.
  //
  //        For Geoserver, a custom template was set up for WMS GetFeatureInfo JSON.
  //        It now returns the following for each feature:
  //
  //        "geoserverLayerName" : "states",
  //        "geoserverWorkspace" : "NamespaceInfoImpl[topp:http://www.openplans.org/topp]",
  //
  //        So both the layer name and workspace can be parsed (and it works on rasters too, 
  //        which have an empty "id").

  // NOTE:  We load the new feature into an IGeoserverFeature, as that interface has the extra 
  //        keys 'geoserverLayerName' and 'geoserverWorkspace'.

  // We need to find the layer this feature belongs to
  let layer: ILayer | null = null;

  if(newGeoJSONFeature.geoserverLayerName)
  {
    // The data came from our own Geoserver
    layer = GetActiveGeoserverLayer(newGeoJSONFeature.geoserverLayerName);
  }
  else
  {
    // If the data didn't come from our geoserver, we default to using the id 
    // as the layer name.
    if(newGeoJSONFeature.id)
      layer = GetLayerByName(newGeoJSONFeature.id.toString());
  }

  //const isParcelLayer: boolean = newGeoJSONFeature.geoserverLayerName === 'Parcels';

  if(!layer) return; // Weren't able to process this feature

  // Find this identify layer if it has already been added
  let identifyLayer: IIdentifyForLayer|null = null;
  for(let p=0; p < outputIdentifyLayers.length; p++)
    if(outputIdentifyLayers[p].layer.name === layer.name)
    {
      identifyLayer = outputIdentifyLayers[p];
      break;
    }

  // Create a new identify layer record if needed

  if(!identifyLayer)
  {
    identifyLayer =
    {
      layer: layer,
      features: [],
    }
    outputIdentifyLayers.push(identifyLayer);
  }

  // Rasters usually have a single attribute, called either GRAY_INDEX, or PALLETTE_INDEX.
  //
  // We often also add in a second attribute called 'feature_info' with contains the friendly
  // name for the index value (so instead for GRAY_INDEX=1 we also get feature_text='Hydric Soil')

  let singleRasterValue: string | undefined = undefined;

  if(newGeoJSONFeature.properties && Object.keys(newGeoJSONFeature.properties).length > 0)
  {
    let indexValue: string | undefined = undefined;
    let feature_text: string | undefined = undefined;

    const propKeys = Object.keys(newGeoJSONFeature.properties);

    if(newGeoJSONFeature.properties)
    {
      for(let i=0; i < propKeys.length; i++)
      {
        const key = propKeys[i];
        const value: string | undefined = newGeoJSONFeature.properties[key];

        if(key === 'GRAY_INDEX' || key === 'PALETTE_INDEX')
        {
          indexValue = value as string;

          // If it ends in '.0' remove that from the string
          if(indexValue && indexValue.endsWith('.0'))
            indexValue = indexValue.substring(0, indexValue.length-2);
        }

        if(key === 'feature_text')
          feature_text = value as string;
      }
    }

    // If the feature_value is available, use that as the "single raster value".
    // This means the identify feature will no longer display a list of attributes 
    // in the UI, it will simply show this one single value instead.
    //
    // If there is no feature_value, but there is a GRAY_INDEX or PALLETE_INDEX,
    // it's value will be used as the single value.
    //
    // If neither are found, the UI will simply show all attribute values in a table.

    if(feature_text)
      singleRasterValue = feature_text;
    else if(indexValue)
      singleRasterValue = indexValue;
  }




  // Add the new feature
  const newFeature : IIdentifyFeature = 
  { 
    id: newGeoJSONFeature.id ? newGeoJSONFeature.id.toString() : '',  // NOTE: This will be empty for GS Identify (for Mapbox it would have the layer/source id)
    data: newGeoJSONFeature.properties, 
    count: identifyLayer.features.length + 1,
    singleRasterValue: singleRasterValue,
  }
  identifyLayer.features.push(newFeature);
}

//-------------------------------------------------------------------------------
// Returns the previously-selected identify layer, based on the state store's
// 'store_lastIdentifyLayerSelected' layer id.
//
// The purpose of this is to have the identify feature re-select whatever layer
// was previously selected (in multi-layer mode) instead of always snapping back
// to the top-most layer.
//-------------------------------------------------------------------------------
export function GetLastSelectedIdentifyLayer(): IIdentifyForLayer | undefined
{
  const store_identify = useStore.getState().store_identify;
  if(!store_identify || store_identify.identifyLayers.length === 0) return undefined;

  // If no layer was selected before, default to the first layer in the list

  const store_lastIdentifyLayerSelected = useStore.getState().store_lastIdentifySelectionLayerID;
  if(!store_lastIdentifyLayerSelected)
    return store_identify.identifyLayers[0];

  // If a layer was previously selected, try to find that layer in the current 
  // identify layer list, and if found, return it.

  for(let i=0; i < store_identify.identifyLayers.length; i++)
    if(store_lastIdentifyLayerSelected === store_identify.identifyLayers[i].layer.id)
      return store_identify.identifyLayers[i];

  // The previously selected layer is not part of this identify request - return the first layer
  return store_identify.identifyLayers[0];
}



/*
//-------------------------------------------------------------------------------
// Render map info.
//-------------------------------------------------------------------------------
function LoadFeatureCollection()
{
  if(!store_mapPropViewerFeatures || store_mapPropViewerFeatures?.features.length === 0) 
    return;

  // Break up the raw FeatureCollection into an array of IPropLayer (easier to manage)

  const propLayers: IIdentifyForLayer[] = [];

  for(let i=0; i < store_mapPropViewerFeatures.features.length; i++)
  {
    // NOTE:  We get data from 2 sources - Mapbox itself, or Geoserver requests.
    //
    //        For Mapbox data, the id contains the layer name.
    //
    //        For Geoserver, a custom template was set up for WMS GetFeatureInfo JSON.
    //        It now returns the following for each feature:
    //
    //        "geoserverLayerName" : "states",
    //        "geoserverWorkspace" : "NamespaceInfoImpl[topp:http://www.openplans.org/topp]",
    //
    //        So both the layer name and workspace can be parsed (and it works on rasters too, 
    //        which have an empty "id").

    // NOTE:  We load it into an IGeoserverFeature, as that interface has the extra 
    //        keys 'geoserverLayerName' and 'geoserverWorkspace'.
    const feature: IGeoserverFeature = store_mapPropViewerFeatures.features[i] as IGeoserverFeature;

    // We need to find the layer this feature belongs to
    let storeLayer: ILayer | null = null;

    if(feature.geoserverLayerName)
    {
      // The data came from our own Geoserver
      storeLayer = GetActiveGeoserverLayer(feature.geoserverLayerName);
    }
    else
    {
      // If the data didn't come from our geoserver, we default to using the id 
      // as the layer name.
      if(feature.id)
        storeLayer = GetLayerByName(feature.id.toString());
    }

    if(!storeLayer) continue; // We weren't able to process this feature - try the next one

    // Find this layer if it has already been added
    let layer: IIdentifyForLayer|null = null;
    for(let p=0; p < propLayers.length; p++)
      if(propLayers[p].layer.name === storeLayer.name)
      {
        layer = propLayers[p];
        break;
      }

    // Create a new IPropLayer record if needed

    if(!layer)
    {
      layer =
      {
        layer: storeLayer,
        features: [],
      }
      propLayers.push(layer);
    }

    // Add the new feature
    const newFeature : IIdentifyFeature = 
    { 
      id: feature.id ? feature.id.toString() : '',
      data: feature.properties, 
      count: layer.features.length + 1
    };
    layer.features.push(newFeature);
  }

  // Update the useState with the new prop layer data
  setPropLayers(propLayers);

  // Reset the "current" values
  setCurrPropLayer(propLayers[0]);
  setCurrFeature(propLayers[0].features[0]);

  // Open the top-most layer's accordion
  setActiveAccordionItem(propLayers[0]);
}
*/

class GeoserverFeaturesQueryResults
{
  public success : boolean = false;
  public errorMessage : string = ''
  public data : FeatureCollection | null = null;

  constructor(success: boolean, errorMessage: string, data: FeatureCollection | null) 
  {
    this.success = success;
    this.errorMessage = errorMessage;
    this.data = data;
  }  

  get failed() { return !this.success; };
}