// Theme


//-------------------------------------------------------------------------------
// Images
//-------------------------------------------------------------------------------

//import stratifyx_logo from './Images/StratifyX_Logo_Horizontal_FullColor - adjusted.png';
import stratifyx_logo_white_icon_only_small from './Images/StratifyX_Logo_White_Icon_Only_Small.png';
import stratifyx_logo_white_text_only_small from './Images/StratifyX_Logo_White_Text_Only_Small.png';
import stratifyx_bg_image from './Images/shutterstock_298180979+[Converted]-01.jpg';

import stratifyx_logo_dark from './Images/Stratifyx_Logo_Primary_1C.png';
import stratifyx_logo_light from './Images/Stratifyx_Logo_Reverse_1C.png';


export { stratifyx_logo_dark };
export { stratifyx_logo_light };


//export { stratifyx_logo };
export { stratifyx_logo_white_icon_only_small };
export { stratifyx_logo_white_text_only_small };
export { stratifyx_bg_image };


//export const stratifyx_logo_for_light_bg = require("./Images/StratifyX_Logo_Horizontal_FullColor - adjusted.png").default;
//export const stratifyx_logo_for_dark_bg = require("./Images/StratifyX_Logo_Vertical_Color_Reversed_small.png");
//import stratifyx_logo_for_light_bg from 'Images/StratifyX_Logo_Horizontal_FullColor - adjusted.png';
//export const stratifyx_logo_white_icon_only_small = require("./Images/StratifyX_Logo_White_Icon_Only_Small.png").default;
//export const stratifyx_logo_white_icon_only_medium = require("./Images/StratifyX_Logo_White_Icon_Only_Medium.png");
//export const stratifyx_logo_white_icon_only_large = require("./Images/StratifyX_Logo_White_Icon_Only_Large.png");
//export const stratifyx_logo_white_text_only_large = require("./Images/StratifyX_Logo_White_Text_Only_Large.png");


export const DEFAULT_AOI_COLOR = '#3BB2D0';  // Light cyan
export const DEFAULT_AOI_OPACITY = 1;


//-------------------------------------------------------------------------------
// Colors - BLUE theme
//-------------------------------------------------------------------------------

// Blue background gradient (only work with 'background' css tag, not bgcolor)
export const theme_bgColorGradient = 'linear-gradient(to right bottom, #193D7D, #14489E, #193D7D)';
export const theme_bgColorGradient2 = 'linear-gradient(to right bottom, #163770, #12314A, #163770)';

// Background colors
export const theme_bgColorMain = '#193D7D';
export const theme_bgColorLight1 = '#3667AD';
export const theme_bgColorLight = '#5A77A9'; //'#587BB7';  // ?'#5A77A9'

// Text colors
export const theme_textColorMain = '#FFFFFF';
export const theme_textColorBlended = '#8DB6FF';
export const theme_errorRed = '#f6685e';

// Accent colors
export const theme_orange = '#E7A44F';  // logo orange
export const theme_limeGreen = '#C1C967'; // '#BDC565' was orig color from logo - Jessica suggested a lighter color;

//-------------------------------------------------------------------------------
// Colors - GREEN theme 1
//-------------------------------------------------------------------------------
/*
// Blue background gradient (only work with 'background' css tag, not bgcolor)
export const theme_bgColorGradient = 'linear-gradient(to right bottom, #00483A, #006350, #00483A)';
export const theme_bgColorGradient2 = 'linear-gradient(to right bottom, #003D31, #005242, #003D31)';

// Background colors
export const theme_bgColorMain = '#00483A';
export const theme_bgColorLight1 = '#00663E';
export const theme_bgColorLight = '#008F73'; //'#587BB7';  // ?'#5A77A9'

// Text colors
export const theme_textColorMain = '#FFFFFF';
export const theme_textColorBlended = '#6F9E72';
export const theme_errorRed = '#f6685e';

// Accent colors
export const theme_orange = '#D78A2B';  // logo orange
export const theme_limeGreen = '#C1C967'; // '#BDC565' was orig color from logo - Jessica suggested a lighter color;
*/